exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-black-entrepreneurs-js": () => import("./../../../src/pages/black-entrepreneurs.js" /* webpackChunkName: "component---src-pages-black-entrepreneurs-js" */),
  "component---src-pages-bookcall-js": () => import("./../../../src/pages/bookcall.js" /* webpackChunkName: "component---src-pages-bookcall-js" */),
  "component---src-pages-coaching-js": () => import("./../../../src/pages/coaching.js" /* webpackChunkName: "component---src-pages-coaching-js" */),
  "component---src-pages-first-nations-js": () => import("./../../../src/pages/first-nations.js" /* webpackChunkName: "component---src-pages-first-nations-js" */),
  "component---src-pages-hrm-js": () => import("./../../../src/pages/hrm.js" /* webpackChunkName: "component---src-pages-hrm-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-methods-js": () => import("./../../../src/pages/methods.js" /* webpackChunkName: "component---src-pages-methods-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-wellbeing-js": () => import("./../../../src/pages/wellbeing.js" /* webpackChunkName: "component---src-pages-wellbeing-js" */)
}

